



































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import {AxiosResponse} from 'axios';
import moment from 'moment';
import Feed from '@/components/Feed.vue';
import Website from '@/components/modals/Website.vue';
import Loader from '@/components/Loader.vue';
import ProfileSettings from '@/components/modals/ProfileSettings.vue';
import NoContent from '@/components/NoContent.vue';
import LoaderBottom from '@/components/LoaderBottom.vue';
import FeedMixin from '@/mixins/FeedMixin';
import {UserDataInterface} from '@/types/UserDataInterface';
import WebsiteInterface from '@/types/WebsiteInterface';
import PostInterface from '@/types/PostInterface';
import PostAuthorInterface from '@/types/PostAuthorInterface';
import {RoomInterface} from '@/types/RoomInterface';
import clickOutside from '@/utils/clickOutsideElement';
import BlockUserConfirmation from '@/components/modals/BlockUserConfirmation.vue';
import ReportUser from '@/components/modals/ReportUser.vue';

@Component({
  name: 'Profile',
  components: {ReportUser, BlockUserConfirmation, ProfileSettings, Website, Feed, Loader, NoContent, LoaderBottom},
  filters: {
    joinedAgo(createdAt: string | undefined): string {
      return createdAt ? moment(createdAt).fromNow() : '';
    },
    formattedCreatedAt(createdAt: string | undefined): string {
      return createdAt ? `Joined ${moment(createdAt).format('MM/YY')}` : '';
    },
    formatNumber(number: number): string | number {
      if (!number) {
        return 0;
      }
      const digits = number.toString().length;
      switch (true) {
        case digits < 4:
          return number;
        case digits < 7:
          return `${Math.round((number / 1000 + Number.EPSILON) * 100) / 100}k`;
        case digits < 10:
          return `${Math.round((number / 1000000 + Number.EPSILON) * 100) / 100}m`;
        case digits < 13:
          return `${Math.round((number / 1000000000 + Number.EPSILON) * 100) / 100}b`;
        case digits < 16:
          return `${Math.round((number / 1000000000000 + Number.EPSILON) * 100) / 100}t`;
        default:
          return '';
      }
    },
  },
  directives: {
    clickOutside,
  },
})
export default class Profile extends Mixins(FeedMixin) {
  loading = true;
  user = {} as UserDataInterface;
  sites = [] as WebsiteInterface[];
  showWebsiteModal = false;
  websiteEditing = false;
  website = {} as WebsiteInterface;
  showSettingsModal = false;
  showProfileMenu = false;
  showBlockConfirmation = false;
  showReportUser = false;
  userId = 0 as number;

  get isBlocked(): boolean {
    return vxm.user.blockedUsers.findIndex((x: UserDataInterface) => x._id === this.user._id) !== -1;
  }

  get isMuted(): boolean {
    return vxm.user.mutedUsers.findIndex((x: UserDataInterface) => x._id === this.user._id) !== -1;
  }

  get isOwnProfile(): boolean {
    return this.$route.params.profile === vxm.user.data.username;
  }

  get isUserFollowed(): boolean {
    return this.user.isFollowing;
  }

  get isProfilePrivate(): boolean {
    return this.$route.params.profile === this.$route.name ? false : this.user.isPrivate;
  }

  get feedTitle(): string {
    return `All posts (${this.total})`;
  }

  get avatar(): string {
    if (this.user.mediaId) {
      return (
        (this.user.mediaId.link && (this.user.mediaId.link.medium || this.user.mediaId.link.origin)) ||
        require('@/assets/icons/avatar-default.svg')
      );
    }
    return require('@/assets/icons/avatar-default.svg');
  }

  get noPostsMessage(): string {
    return this.isOwnProfile
      ? 'You have no posts published...\nTry to create one!'
      : 'This user has no posts now...\nTry to come later!';
  }

  get author(): PostAuthorInterface {
    return {name: this.user.name, username: this.user.username, avatar: this.avatar};
  }

  get isRequested(): boolean {
    return this.user.isAskFollowing;
  }

  get profileNeedToUpdate(): boolean {
    return vxm.post.profileNeedToUpdate;
  }

  @Watch('$route.params.profile')
  onProfileChange() {
    this.loading = true;
    this.user = {} as UserDataInterface;
    this.feed = [];
    this.page = 1;
    this.complete = false;
    this.getProfileData();
  }

  @Watch('profileNeedToUpdate') onUpdNeeded() {
    if (this.profileNeedToUpdate) {
      this.loading = true;
      this.feed = [];
      this.page = 1;
      this.complete = false;
      this.getProfileData();
    }
  }

  mounted() {
    this.getProfileData();
  }
  closeReportModal(confirm: boolean) {
    this.showReportUser = false;
    if (confirm) {
      this.getProfileData();
    }
  }
  getProfileData(): void {
    vxm.user
      .getProfileData(this.$route.params.profile)
      .then((res: {data: {data: UserDataInterface}}) => {
        this.user = res.data.data;
        Promise.all([
          vxm.user.getWebsites({userId: this.user._id}),
          vxm.user.getPosts({
            id: this.user._id,
            page: this.page,
            limit: this.limit,
          }),
        ])
          .then(async (res: [{data: {data: WebsiteInterface[]}}, {data: {items: PostInterface[]; total: number}}]) => {
            this.sites = res[0].data.data;
            this.feed.push(...res[1].data.items);
            this.total = res[1].data.total;
            await this.onFeedGrow();
            vxm.post.setProfileNeedToUpdate(false);
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch(() => {
        this.$router.push('/');
      });
  }

  getFeed(): void {
    if (this.user._id) {
      vxm.user
        .getPosts({
          id: this.user._id,
          page: this.page,
          limit: this.limit,
        })
        .then(async (res: {data: {items: PostInterface[]; total: number}}) => {
          this.feed.push(...res.data.items);
          this.total = res.data.total;
          await this.onFeedGrow();
        });
    }
  }

  showEditSiteModal(index: number): void {
    this.website = this.sites[index];
    this.websiteEditing = true;
    this.showWebsiteModal = true;
  }

  showAddSiteModal(): void {
    this.website = {} as WebsiteInterface;
    this.websiteEditing = false;
    this.showWebsiteModal = true;
  }

  changeFollowState(): void {
    vxm.user.follow({userId: this.user._id}).then(() => {
      if (this.user.isPrivate && !this.user.isFollowing) {
        this.user.isAskFollowing = true;
      } else {
        this.user.isFollowing = !this.user.isFollowing;
        this.user.followersCount += this.user.isFollowing ? 1 : -1;
      }
    });
  }

  onMessageClick(): void {
    vxm.user.createRoom({userId: this.user._id}).then((res: AxiosResponse<RoomInterface>) => {
      this.$router.push(`/chat?chatId=${res.data._id}`);
    });
  }

  updateUser(): void {
    this.showSettingsModal = false;
    if (this.user.username !== vxm.user.data.username) {
      this.$router.push(`/${vxm.user.data.username}`);
    } else {
      this.user = vxm.user.data;
    }
  }

  updateWebsites(): void {
    this.showWebsiteModal = false;
    this.loading = true;
    vxm.user
      .getWebsites({userId: this.user._id})
      .then((res: {data: {data: WebsiteInterface[]}}) => {
        this.sites = res.data.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getSiteCover(index: number): string {
    return (
      (this.sites[index].cover?.link &&
        (this.sites[index].cover?.link.medium || this.sites[index].cover?.link.origin)) ||
      require('@/assets/icons/website-default.svg')
    );
  }

  onWebsiteClick(url: string): void {
    window.open(url, '_blank');
  }

  onFollowingCountChanged(changedBy: number): void {
    this.user.followingCount = this.user.followingCount + changedBy;
  }

  closeProfileMenu() {
    this.showProfileMenu = false;
  }
  reportUser() {
    this.closeProfileMenu();
    this.showReportUser = true;
  }

  copyProfileLink() {
    this.closeProfileMenu();
    navigator.clipboard.writeText(window.location.href);
    this.$toasted.show('Copied to clipboard', {
      className: 'toasted-info',
    });
  }

  muteUser() {
    this.closeProfileMenu();
    if (this.isMuted) {
      vxm.user.unmuteUser(this.user._id).then(() => {
        this.$toasted.show(`@${this.user.username} has been unmuted`, {
          className: 'toasted-info',
        });
      });
    } else {
      vxm.user.muteUser(this.user._id).then(() => {
        this.$toasted.show(`@${this.user.username} has been muted`, {
          className: 'toasted-info',
        });
      });
    }
  }

  blockUser() {
    this.closeProfileMenu();
    if (this.isBlocked) {
      vxm.user.unblockUser(this.user._id).then(() => {
        this.$toasted.show(`@${this.user.username} has been unblocked`, {
          className: 'toasted-info',
        });
      });
    } else {
      this.showBlockConfirmation = true;
    }
  }

  blockConfirmation(confirm: boolean) {
    this.showBlockConfirmation = false;
    if (confirm) {
      vxm.user.blockUser(this.user._id).then(() => {
        this.$toasted.show(`@${this.user.username} has been blocked`, {
          className: 'toasted-info',
        });
      });
    }
  }
}
