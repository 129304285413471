













import {Component, Prop, Vue} from 'vue-property-decorator';
import clickInside from '@/utils/clickInside';

@Component({
  directives: {
    clickInside,
  },
})
export default class BlockUserConfirmation extends Vue {
  @Prop(String) readonly username!: string;
  closeModal(confirm = false): void {
    this.$emit('closeModal', confirm);
  }
}
