






































































































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {helpers, maxLength, minLength, required} from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import {vxm} from '@/store';
import BhInput from '@/components/BhInput.vue';
import BhSelect from '@/components/BhSelect.vue';
import Loader from '@/components/Loader.vue';
import BhTextarea from '@/components/BhTextarea.vue';
import ProfileAvatarPicker from '@/components/modals/ProfileAvatarPicker.vue';
import ProfileWatchPreferences from '@/components/ProfileWatchPreferences.vue';
import ProfileActivityPreferences from '@/components/ProfileActivityPreferences.vue';
import usernameRegexp, {maxUsernameLength, minUsernameLength} from '@/constants/usernameRegex';
import {hasRestrictedWords, matchRestrictedWord} from '@/constants/restrictedWordsValidation';
import UsernamePrepareMixin from '@/mixins/UsernamePrepareMixin';
import clickInside from '@/utils/clickInside';
import debounce from '@/utils/debounce';
import MobileDatePicker from '@/components/MobileDatePicker.vue';
import NoScrollMixin from '@/mixins/NoScrollMixin';
import IsMobileMixin from '@/mixins/isMobileMixin';
import nameRegexp, {maxNameLength, minNameLength} from '@/constants/nameRegex';
import {UserDataInterface} from '@/types/UserDataInterface';

Vue.use(Vuelidate);

@Component({
  name: 'ProfileSettings',
  components: {
    MobileDatePicker,
    ProfileAvatarPicker,
    BhTextarea,
    BhInput,
    BhSelect,
    Loader,
    DatePicker,
    ProfileWatchPreferences,
    ProfileActivityPreferences,
  },
  directives: {
    clickInside,
  },
  validations: {
    user: {
      name: {
        required,
        alpha: helpers.regex('alpha', nameRegexp),
        minLength: minLength(minNameLength),
        maxLength: maxLength(maxNameLength),
        hasRestrictedWords,
      },
      username: {
        required,
        alpha: helpers.regex('alpha', usernameRegexp),
        minLength: minLength(minUsernameLength),
        maxLength: maxLength(maxUsernameLength),
        hasRestrictedWords,
      },
      bio: {maxLength: maxLength(200), hasRestrictedWords},
      location: {minLength: minLength(3), maxLength: maxLength(25)},
    },
    otherEthnicity: {maxLength: maxLength(25)},
    inClothesOther: {maxLength: maxLength(25)},
    postAndShareClothedPreferencesOther: {maxLength: maxLength(25)},
  },
})
export default class ProfileSettings extends Mixins(UsernamePrepareMixin, NoScrollMixin, IsMobileMixin) {
  @Prop({type: String, required: true}) readonly avatar!: string;

  loading = true;
  user = {
    isPrivate: false,
    isOnNotifications: false,
    isDarkMode: false,
    email: '',
    name: '',
    username: '',
    bio: '',
    birthDate: '',
    location: '',
  } as {[key: string]: string | boolean};
  ethnicity = '';
  isMixedRace = false;
  mixedRace = {
    asian: false,
    afroLatino: false,
    black: false,
    caucasianWhite: false,
    latinoHispanic: false,
    middleEastern: false,
    nativeAmerican: false,
    pacificIslander: false,
  } as {[key: string]: boolean};
  isOtherEthnicity = false;
  otherEthnicity = '';
  showProfileSettings = true;
  showContentSettings = false;
  showMuteAndBlock = false;
  showBlock = true;
  showAvatarPicker = false;
  avatarPicked = '';
  mediaId = '';
  usernameIsFree = true;
  debounceHandler = () => {
    /* Will be replaced */
  };
  datePickerFormat = 'MM-DD-YYYY';
  enums = {} as {[key: string]: {[key: string]: string | {[key: string]: string}}};
  ethnicityEnum = {} as {[key: string]: string};
  wantToSee = {} as {[key: string]: string[]};
  otherWantToSee = '';
  wantToSeeErrors = false;
  wantToPost = {} as {[key: string]: string};
  otherWantToPost = '';
  wantToPostErrors = false;
  blockRequestSend = false;
  muteRequestSend = false;

  get nameErrorMessage(): string {
    if (!this.$v.user.name?.required) {
      return 'Name is a required field';
    }
    if (!this.$v.user.name?.alpha) {
      return 'Name should contain only lowercase, uppercase letters and hyphens';
    }
    if (!this.$v.user.name?.hasRestrictedWords) {
      return 'Input contains restricted word: ' + matchRestrictedWord(this.$v.user.name.$model);
    }
    return `Name should be ${minNameLength}-${maxNameLength} symbols long`;
  }

  get usernameErrorMessage(): string {
    if (!this.$v.user.username?.required) {
      return 'Username is a required field';
    }
    if (!this.$v.user.username?.alpha) {
      return 'Username should contain only lowercase letters, digits and hyphens';
    }
    if (!this.usernameIsFree) {
      return 'Username is already taken';
    }
    if (!this.$v.user.username?.hasRestrictedWords) {
      return 'Input contains restricted word: ' + matchRestrictedWord(this.$v.user.username.$model);
    }
    return `Username should be ${minUsernameLength}-${maxUsernameLength} symbols long`;
  }

  get bioErrorMessage(): string {
    return !this.$v.user.bio?.maxLength
      ? `Bio should be up to ${this.$v.user.bio?.$params.maxLength.max} symbols long`
      : 'Input contains restricted word: ' + matchRestrictedWord(this.$v.user.bio.$model);
  }

  get blockedUsers(): UserDataInterface[] {
    return vxm.user.blockedUsers;
  }

  get mutedUsers(): UserDataInterface[] {
    return vxm.user.mutedUsers;
  }

  get ethnicityErrorMessage(): string {
    return 'Choose ethnicity';
  }

  get mixedEthnicityErrorMessage(): string {
    return 'Choose at least one ethnicity';
  }

  get locationErrorMessage(): string {
    return `Location should be ${this.$v.user.location?.$params.minLength.min}\
-${this.$v.user.location?.$params.maxLength.max} symbols long`;
  }

  get otherEthnicityErrorMessage(): string {
    return this.otherEthnicityRequiredError
      ? 'This field should not be empty'
      : `This field should be up to ${this.$v.otherEthnicity?.$params.maxLength.max} symbols long`;
  }

  get underage(): boolean {
    if (this.user.birthDate) {
      const birthday = moment(this.user.birthDate as string, 'D MMMM YYYY');
      const age = moment.duration(moment().diff(birthday)).asYears();
      return age < 18;
    }
    return false;
  }

  get mixedEthnicityError(): boolean {
    return this.isMixedRace && Object.values(this.mixedRace).every((val: boolean) => !val);
  }

  get otherEthnicityRequiredError(): boolean {
    return this.isOtherEthnicity && !this.otherEthnicity;
  }

  get birthDate(): Date {
    return this.user.birthDate ? new Date(`${this.user.birthDate}`) : new Date();
  }

  set birthDate(val: Date) {
    this.user.birthDate = val.toUTCString();
  }

  get profileSettingsError(): boolean {
    return (
      this.$v.$invalid ||
      !this.usernameIsFree ||
      this.underage ||
      this.mixedEthnicityError ||
      this.otherEthnicityRequiredError ||
      this.ethnicityError
    );
  }

  get ethnicityError(): boolean {
    return !this.isMixedRace && !this.isOtherEthnicity && !this.ethnicity;
  }

  get contentSettingsError(): boolean {
    return this.wantToSeeErrors || this.wantToPostErrors;
  }

  @Watch('user.username')
  onUsernameChanged() {
    this.debounceHandler();
  }

  @Watch('$route.path')
  onRouteChanged() {
    this.closeSettings();
  }

  created() {
    this.debounceHandler = debounce(this.onUsernameInput, 500);
    Object.keys(this.user).forEach((key) => {
      if (vxm.user.data[key]) {
        this.user[key] = vxm.user.data[key];
      }
    });
    this.getEnums();
  }

  getEnums() {
    vxm.user
      .getEnums()
      .then((res: {data: {data: any}}) => {
        this.enums = res.data.data;
        this.ethnicityEnum = this.enums.ethnicity as {[key: string]: string};
        this.setEthnicity();
      })
      .catch(() => {
        this.closeSettings();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.profileSettingsError || this.contentSettingsError) {
      if (this.profileSettingsError && !this.showProfileSettings) {
        this.showProfileSettings = true;
        this.showContentSettings = false;
      } else if (this.contentSettingsError && !this.showContentSettings) {
        this.showProfileSettings = false;
        this.showContentSettings = true;
      }
      return;
    }
    let changes = false;
    this.loading = true;
    if (this.avatarPicked) {
      await vxm.user
        .updateAvatar({files: [await this.getFileFromDataUrl(this.avatarPicked)]})
        .then((res: {data: {data: {_id: string}[]}}) => {
          this.mediaId = res.data.data[0]._id;
        });
    }
    const params = this.getParams();
    Object.keys(params).forEach((key) => {
      if (!this.user.key || params[key] !== this.user.key) {
        changes = true;
      }
    });
    if (!changes && !this.avatarPicked) {
      this.closeSettings();
      return;
    }
    vxm.user
      .updateUser(params)
      .then(() => {
        this.$toasted.show('Profile is successfully updated.', {
          className: 'toasted-info',
        });
        this.$emit('userUpdated');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  switchTab(key: string) {
    this.showProfileSettings = false;
    this.showContentSettings = false;
    this.showMuteAndBlock = false;
    (this as any)[key] = true;
  }

  getParams(): {[key: string]: string | boolean | string[] | {[key: string]: string[] | string}} {
    const params = [] as {[key: string]: string | boolean | string[] | {[key: string]: string[] | string}}[];
    Object.keys(this.user).forEach((key) => {
      if (
        (!Object.prototype.hasOwnProperty.call(vxm.user.data, key) && this.user[key]) ||
        vxm.user.data[key] !== this.user[key]
      ) {
        params.push({[key]: this.user[key]});
      }
    });
    const ethnicityParams = this.getEthnicityParams();
    if (ethnicityParams) {
      params.push(ethnicityParams);
    }
    if (this.wasWantToSeeParamsChanged()) {
      params.push({wantToSee: this.wantToSee});
    }
    if (this.wasOtherWantToSeeChanged()) {
      params.push({otherWantToSee: this.otherWantToSee});
    }
    if (this.wasWantToPostParamsChanged()) {
      params.push({wantToPost: this.wantToPost});
    }
    if (this.wasOtherWantToPostChanged()) {
      params.push({otherWantToPost: this.otherWantToPost});
    }
    if (this.mediaId) {
      params.push({mediaId: this.mediaId});
    }
    return Object.assign({}, ...params);
  }

  getEthnicityParams(): {[key: string]: string | string[]} | null {
    if (this.isMixedRace) {
      const mixEthnicity = this.getMixedRace();
      if (this.wasMixedRaceEdited(mixEthnicity)) {
        return {mixEthnicity, ethnicity: this.ethnicityEnum.mix};
      }
    } else if (this.isOtherEthnicity && this.otherEthnicity !== vxm.user.data.otherEthnicity) {
      return {otherEthnicity: this.otherEthnicity, ethnicity: this.ethnicity};
    } else if (this.ethnicity !== vxm.user.data.ethnicity) {
      return {ethnicity: this.ethnicity};
    }
    return null;
  }

  onAvatarPicked(avatar: string): void {
    this.avatarPicked = avatar;
  }

  async getFileFromDataUrl(dataUrl: string) {
    return fetch(dataUrl).then((res) => {
      return res.blob().then((res) => {
        return new File([res], 'cover.img', {type: 'image/jpeg'});
      });
    });
  }

  closeSettings(): void {
    this.$emit('closeModal');
  }

  unblockUser(userId: string) {
    this.blockRequestSend = true;
    vxm.user
      .unblockUser(userId)
      .then(() => {
        this.blockRequestSend = false;
        this.$toasted.show(`@${this.user.username} has been unblocked`, {
          className: 'toasted-info',
        });
      })
      .catch(() => {
        this.blockRequestSend = false;
      });
  }

  unmuteUser(userId: string) {
    this.muteRequestSend = true;
    vxm.user
      .unmuteUser(userId)
      .then(() => {
        this.muteRequestSend = false;
        this.$toasted.show(`@${this.user.username} has been unmuted`, {
          className: 'toasted-info',
        });
      })
      .catch(() => {
        this.muteRequestSend = false;
      });
  }

  onUsernameInput(): void {
    this.user.username = this.getPreparedUsername(this.user.username as string);
    this.$v.user.username?.$touch();
    if (this.$v.user.username?.$error) {
      return;
    }
    if (this.user.username === vxm.user.data.username) {
      this.usernameIsFree = true;
      return;
    }
    vxm.user
      .checkUsernameUniqueness(this.user.username)
      .then(() => {
        this.usernameIsFree = true;
      })
      .catch(() => {
        this.usernameIsFree = false;
      });
  }

  onOptionSelected(value: string): void {
    const ethnicityType = Object.keys(this.ethnicityEnum).find((key) => this.ethnicityEnum[key] === value);
    if (ethnicityType) {
      this.ethnicity = value;
      this.isMixedRace = value === this.ethnicityEnum.mix;
      this.isOtherEthnicity = value === this.ethnicityEnum.other;
    }
  }

  getMixedRace(): string[] {
    const mixedRace = [] as string[];
    const ethnicityEnumKeys = Object.keys(this.ethnicityEnum);
    Object.keys(this.mixedRace).forEach((key) => {
      if (this.mixedRace[key]) {
        if (ethnicityEnumKeys.includes(key)) {
          mixedRace.push(this.ethnicityEnum[key]);
        }
      }
    });
    return mixedRace;
  }

  setEthnicity(): void {
    const mixedRace = {} as {[key: string]: boolean};
    Object.keys(this.ethnicityEnum).forEach((key) => {
      if (key !== 'mix' && key !== 'other') {
        mixedRace[key] = false;
      }
    });
    this.mixedRace = mixedRace;

    if (vxm.user.data.mixEthnicity && vxm.user.data.mixEthnicity.length) {
      this.setMixedEthnicity(vxm.user.data.mixEthnicity);
    } else if (vxm.user.data.otherEthnicity) {
      this.otherEthnicity = vxm.user.data.otherEthnicity;
      this.isOtherEthnicity = true;
      this.ethnicity = vxm.user.data.ethnicity || '';
    } else {
      this.ethnicity = vxm.user.data.ethnicity || '';
    }
  }

  setMixedEthnicity(ethnicities: string[]): void {
    Object.entries(this.ethnicityEnum).forEach((entry) => {
      if (ethnicities.includes(entry[1])) {
        this.mixedRace[entry[0]] = true;
      }
    });
    this.isMixedRace = Object.values(this.mixedRace).some((val) => val);
    if (this.isMixedRace) {
      this.ethnicity = this.ethnicityEnum.mix;
    }
  }

  wasMixedRaceEdited(mixedRace: string[]): boolean {
    if (!vxm.user.data.mixEthnicity || vxm.user.data.mixEthnicity.length !== mixedRace.length) {
      return true;
    }
    return mixedRace.some((race, i) => race !== vxm.user.data.mixEthnicity[i]);
  }

  onWatchPreferencesUpdated(payload: {
    gotErrors: boolean;
    otherWantToSee?: string;
    wantToSee: {[key: string]: string[]};
  }): void {
    this.wantToSeeErrors = payload.gotErrors;
    this.wantToSee = payload.wantToSee;
    this.otherWantToSee = payload.otherWantToSee || '';
  }

  onActivityPreferencesUpdated(payload: {
    gotErrors: boolean;
    otherWantToPost?: string;
    wantToPost: {[key: string]: string};
  }): void {
    this.wantToPostErrors = payload.gotErrors;
    this.wantToPost = payload.wantToPost;
    this.otherWantToPost = payload.otherWantToPost || '';
  }

  wasWantToSeeParamsChanged(): boolean {
    if (!this.wantToSee) {
      return false;
    }
    const updatedKeys = Object.keys(this.wantToSee);
    const currentKeys = Object.keys(vxm.user.data.wantToSee);
    if (!vxm.user.data.wantToSee || currentKeys.length !== updatedKeys.length) {
      return true;
    }
    if (currentKeys.length === 0 && updatedKeys.length === 0) {
      return false;
    }
    if (
      updatedKeys.some((key) => !vxm.user.data.wantToSee[key]) ||
      updatedKeys.some((key) => this.wantToSee[key].length !== vxm.user.data.wantToSee[key].length)
    ) {
      return true;
    }
    return updatedKeys.some((key) =>
      this.wantToSee[key].some((option, i) => option !== vxm.user.data.wantToSee[key][i]),
    );
  }

  wasOtherWantToSeeChanged(): boolean {
    return (
      !!Object.keys(this.wantToSee).length &&
      !!this.wantToSee.inCloth &&
      this.wantToSee.inCloth.includes(
        (this.enums.wantToSee as {[key: string]: {[key: string]: string}}).inCloth.other,
      ) &&
      this.otherWantToSee !== vxm.user.data.otherWantToSee
    );
  }

  wasWantToPostParamsChanged(): boolean {
    if (!this.wantToPost) {
      return false;
    }
    const updatedKeys = Object.keys(this.wantToPost);
    const currentKeys = Object.keys(vxm.user.data.wantToPost);
    if (!vxm.user.data.wantToPost || currentKeys.length !== updatedKeys.length) {
      return true;
    }
    if (currentKeys.length === 0 && updatedKeys.length === 0) {
      return false;
    }
    return (
      updatedKeys.some((key) => !vxm.user.data.wantToPost[key]) ||
      updatedKeys.some((key) => this.wantToPost[key] !== vxm.user.data.wantToPost[key])
    );
  }

  wasOtherWantToPostChanged(): boolean {
    return (
      !!Object.keys(this.wantToPost).length &&
      !!this.wantToPost.inCloth &&
      this.wantToPost.inCloth.includes(
        (this.enums.wantToPost as {[key: string]: {[key: string]: string}}).inCloth.other,
      ) &&
      this.otherWantToPost !== vxm.user.data.otherWantToPost
    );
  }
}
