




































import {Component, Prop, Vue} from 'vue-property-decorator';
import clickInside from '@/utils/clickInside';
import {UserDataInterface} from '@/types/UserDataInterface';
import {vxm} from '@/store';

@Component({
  directives: {
    clickInside,
  },
})
export default class ReportUser extends Vue {
  steps = {
    current: 1,
    prev: 0,
  };
  reportIsSend = false;
  params = {
    userId: '',
    type: '',
    reason: '',
  };
  @Prop({default: {}}) readonly user!: UserDataInterface;

  get isBlocked(): boolean {
    return vxm.user.blockedUsers.findIndex((x: UserDataInterface) => x._id === this.user._id) !== -1;
  }

  get isMuted(): boolean {
    return vxm.user.mutedUsers.findIndex((x: UserDataInterface) => x._id === this.user._id) !== -1;
  }

  mounted() {
    this.params.userId = this.user._id;
  }

  setReason(type: string, reason?: string) {
    this.params.type = type;
    if (reason) {
      this.params.reason = reason;
    } else {
      delete this.params.reason;
    }
    if (type === 'spreadingAbuse' && !reason) {
      this.steps.current = 2;
      this.steps.prev = 1;
    } else {
      this.sendReport();
      if (this.isBlocked) {
        this.steps.current = 4;
        this.steps.prev = 3;
      } else {
        this.steps.current = 3;
        if (reason) {
          this.steps.prev = 2;
        } else {
          this.steps.prev = 1;
        }
      }
    }
  }

  goBack() {
    if (this.steps.current > 2) {
      this.closeModal();
    } else {
      this.steps.current = this.steps.prev;
      this.steps.prev--;
    }
  }

  done() {
    if (!this.reportIsSend) {
      this.sendReport();
    }
    this.closeModal();
  }

  unfollow() {
    this.steps.current = 4;
    this.steps.prev = 3;
    vxm.user.follow({userId: this.user._id});
  }

  mute() {
    this.steps.current = 4;
    this.steps.prev = 3;
    vxm.user.muteUser(this.user._id);
  }

  block() {
    this.steps.current = 4;
    this.steps.prev = 3;
    vxm.user.blockUser(this.user._id);
  }

  sendReport() {
    vxm.user.reportUser(this.params);
    this.reportIsSend = true;
  }

  closeModal(): void {
    this.$emit('closeModal', this.reportIsSend);
  }
}
