



































import {Component, Prop, Vue} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {helpers, maxLength, minLength, required, url} from 'vuelidate/lib/validators';
import {vxm} from '@/store';
import BhInput from '@/components/BhInput.vue';
import Confirmation from '@/components/modals/Confirmation.vue';
import Loader from '@/components/Loader.vue';
import {ApiMediaTypes} from '@/constants/apiMediaTypes';
import CroppieMixin from '@/mixins/CroppieMixin';
import WebsiteInterface from '@/types/WebsiteInterface';
import ImageLinkSizesInterface from '@/types/ImageLinkSizesInterface';
import clickInside from '@/utils/clickInside';
import untrimmedStringRegex from '@/constants/untrimmedStringRegex';
import {hasRestrictedWords, matchRestrictedWord} from '@/constants/restrictedWordsValidation';

Vue.use(Vuelidate);

@Component({
  name: 'Website',
  components: {Confirmation, BhInput, Loader},
  directives: {
    clickInside,
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
        alpha: helpers.regex('alpha', untrimmedStringRegex),
        hasRestrictedWords,
      },
      domain: {required, url, hasRestrictedWords},
    },
  },
})
export default class Website extends CroppieMixin {
  @Prop({type: Boolean, required: true}) readonly websiteEditing!: boolean;
  @Prop({
    type: Object,
    default() {
      return {title: '', domain: '', cover: ''};
    },
  })
  readonly website!: WebsiteInterface;
  @Prop({type: String, default: ''}) readonly websiteId!: string;

  loading = false;
  form = {} as WebsiteInterface;
  deletingWebsite = false;

  get title(): string {
    return this.websiteEditing ? 'Edit website' : 'Add new website';
  }

  get titleErrorMessage(): string {
    if (!this.$v.form.title?.required) {
      return 'Title is a required field.';
    }
    if (!this.$v.form.title?.alpha) {
      return 'Title should not start and/or end with spaces.';
    }
    if (!this.$v.form.title?.hasRestrictedWords) {
      return 'Input contains restricted word: ' + matchRestrictedWord(this.$v.form.title?.$model);
    }
    return `Title should be ${this.$v.form.title?.$params.minLength.min}-${this.$v.form.title?.$params.maxLength.max} symbols long.`;
  }

  get domainErrorMessage(): string {
    if (!this.$v.form.domain?.required) {
      return 'Domain is a required field.';
    }
    if (!this.$v.form.domain?.hasRestrictedWords) {
      return 'Input contains restricted word: ' + matchRestrictedWord(this.$v.form.domain?.$model);
    }
    return 'Domain should be a valid url.';
  }

  get submitButtonMessage(): string {
    return this.websiteEditing ? 'Save changes' : 'Add new website';
  }

  get siteCover(): string {
    if (this.website.cover) {
      const links = this.website.cover?.link as ImageLinkSizesInterface;
      return links ? links.medium || links.origin : require('@/assets/icons/website-default.svg');
    }
    return this.cropped || require('@/assets/icons/website-default.svg');
  }

  mounted() {
    this.form = Object.assign({}, this.website);
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid || this.$v.$error) {
      return;
    }
    this.loading = true;
    let coverId = '';
    if (this.cropBlock) {
      await this.crop();
    }
    if (this.cropped) {
      const cover = await this.getFileFromDataUrl(this.cropped);
      this.loading = true;
      await vxm.post
        .addMedia({files: [cover], contentType: ApiMediaTypes.Avatar})
        .then((res: {data: {data: {_id: string}[]}}) => {
          coverId = res.data.data[0]._id;
        });
    }
    if (this.websiteEditing) {
      vxm.website
        .updateWebsite({website: this.getParams(coverId), id: this.website._id})
        .then(() => {
          this.$toasted.show('Website is successfully updated.', {
            className: 'toasted-info',
          });
          this.$emit('websitesUpdated');
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      vxm.website
        .addWebsite(this.getParams(coverId))
        .then(() => {
          this.$toasted.show('Website is successfully created.', {
            className: 'toasted-info',
          });
          this.$emit('websitesUpdated');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  onDeleteClicked(): void {
    this.deletingWebsite = true;
  }

  deleteWebsite(): void {
    this.deletingWebsite = false;
    vxm.website
      .deleteWebsite(this.website._id)
      .then(() => {
        this.$toasted.show('Website is successfully deleted.', {
          className: 'toasted-info',
        });
        this.$emit('websitesUpdated');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  openFileDialogueWindow(): void {
    const el = document.getElementById('cover-input') as HTMLElement;
    if (el) {
      el.click();
    }
  }

  previewImage(event: Event): void {
    this.croppie(event);
    const target = event.target as HTMLInputElement;
    target.value = '';
  }

  closeModal(): void {
    this.$emit('closeModal');
  }

  async getFileFromDataUrl(dataUrl: string) {
    return fetch(dataUrl).then((res) => {
      return res.blob().then((res) => {
        return new File([res], 'cover.img', {type: 'image/jpeg'});
      });
    });
  }

  onBack(): void {
    this.croppieImage = '';
    this.cropBlock = false;
  }

  getParams(coverId: string) {
    const params = [];
    params.push({title: this.form.title});
    params.push({domain: this.form.domain});
    if (coverId) {
      params.push({cover: coverId});
    }
    return Object.assign({}, ...params);
  }
}
