





















import {Component} from 'vue-property-decorator';
import CroppieMixin from '@/mixins/CroppieMixin';
import clickInside from '@/utils/clickInside';

@Component({
  name: 'ProfileAvatarPicker',
  directives: {
    clickInside,
  },
})
export default class ProfileAvatarPicker extends CroppieMixin {
  loading = false;

  onAvatarCropped(): void {
    this.loading = true;
    this.crop()
      .then(() => {
        if (this.cropped) {
          this.$emit('avatarPicked', this.cropped);
          this.closeModal();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  onBack(): void {
    if (this.cropBlock) {
      this.croppieImage = '';
      this.cropBlock = false;
    } else {
      this.closeModal();
    }
  }
  closeModal(): void {
    this.$emit('closeModal');
  }
}
